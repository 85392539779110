'use client'

import { useEffect, useState } from "react"
import { useCurrentUser } from "./UserContext"
import LoginScreen from "./screens/LoginScreen"
import ApiUtils from "@/utils/ApiUtils"
import { emitEvent } from "@/utils/EventListener"
import DashLoginScreen from "./screens/DashLoginScreen"

export default function AuthUserWrapper(props) {

  const userContext = useCurrentUser()

  const [inititialized, setInitialized] = useState(false)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    check()
  }, [])

  const check = () => {
    ApiUtils.checkAuth().then(data => {
      setInitialized(true)
      if (data.user) {
        setSuccess(true)
        userContext.setUser(data.user)
        emitEvent("user-logged-in", data.user)
      }
    })
  }

  const onLogin = () => {
    setSuccess(true)
    check()
  }

  if (!inititialized) {
    return <></>
  } else {
    if (success || props.web) {
      return props.children
    } else {
      return <DashLoginScreen onLogin={e => onLogin()}></DashLoginScreen>
    }
  }

}