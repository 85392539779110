'use client'

import Link from 'next/link'
import styles from './ar.module.scss'
import { UserProvider, useCurrentUser } from './UserContext'
import { useEffect } from 'react'
import AuthUserWrapper from './AuthUserWrapper'

const MAIN_MENUS = [
  { label: 'Register', link: "/register" },
  { label: 'Login', link: "/login" },
]
const USER_MENUS = [
  { label: 'Your Cards', link: "/scans" },
]

export default function WebUserAccountHeader(props) {

  const userContext = useCurrentUser()

  const menus = props.mode ? [] : MAIN_MENUS

  useEffect(() => {
    console.log(userContext?.user)
  }, [userContext?.user])


  return userContext?.user ?
      <>
      { !props.mode && USER_MENUS.map((item,idx) => (
      <div key={idx} className='min-f-xs'>
        <Link href={ item.link } className={styles['header-bar-link']}>{ item.label }</Link>
      </div>
      ))}
      <div className='min-f-xs'>
        <Link href={ "/" } className={styles['header-bar-link']} style={{textTransform: 'none'}}>{ "Hi " + (userContext.user.first_name || userContext.user.email.split("@")[0]) + "!" }</Link>
      </div>
      </>
      :
      <>
        { !props.mode && MAIN_MENUS.map((item,idx) => (
        <div key={idx} className='min-f-xs'>
          <Link href={ item.link } className={styles['header-bar-link']}>{ item.label }</Link>
        </div>
        ))}
      </>

}