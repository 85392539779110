'use client'

import { useEffect } from "react"
import Rollbar from "rollbar"
import { useCurrentUser } from "@/views/UserContext"
import useEventListener from "./EventListener"

export default function RollbarPlugin(props) {

  const userContext = useCurrentUser()

  let rollbar = null

  useEventListener("log_error", event => {
    rollbar.error(event.detail.message, event.detail.data)
  })
  
  useEffect(() => {
    const rollbarConfig = {
      enabled: process.env.NODE_ENV != 'development',
      accessToken: process.env.NEXT_PUBLIC_ROLLBAR_ACCESS_TOKEN,
      payload: {
        environment: process.env.NEXT_PUBLIC_ROLLBAR_ENV || 'production',
        client: {
          javascript: {
            source_map_enabled: true,
            code_version: process.env.NEXT_PUBLIC_VERSION,
            guess_uncaught_frames: true
          }
        },
        server: {
          root: process.env.BASE_DOMAIN + "/",
          branch: process.env.NODE_ENV == 'production' ? "main" : "dev",
        },
        code_version: process.env.NEXT_PUBLIC_VERSION,
        custom: {
          site: process.env.BASE_DOMAIN,
        },
        person: {
          id: userContext?.user?.user_id,
          email: userContext?.user?.email,
          name: userContext?.user?.name,
        }
      },
      captureUncaught: true,
      captureUnhandledRejections: true,
    }
  
    rollbar = new Rollbar(rollbarConfig)
    
  }, [])
  
  return <></>
  
}