import styles from './ar.module.scss'
import Page from '../web/common/Page';
import LoginForm from '../partials/LoginForm';

export default function LoginScreen(props) {

  return <Page>
    <div className={styles.login}>
      {/* <Image src={cdn() + "/images/stock/home-hero.jpg?1"} alt={'Login Screen Background'} fill={'object-fit'} style={{ maxWidth: "100%", objectFit: "cover" }} priority={true} sizes={'1024x1024'}></Image> */}
      {/* <div className={styles.login_blur}></div> */}
      <LoginForm {...props} className={styles['login-form']}></LoginForm>
      </div>
    </Page>


}