import { cdn } from '@/utils/Helpers'
import styles from './a.module.scss'
import AppPageBanner from './AppPageBanner'
import PageColumn from './PageColumn'
import PageFooter from './PageFooter'
import Link from 'next/link'
import Image from 'next/image'
import HeaderUser from './HeaderUser'
import WebUserContext from '@/views/WebUserContext'
import { HEART, SEARCH } from '@/views/Icons'

export default function Page(props) {

  const highlights = [
    { link: "/items/s/Baseball", label: 'Baseball' },
    { link: "/items/s/Basketball", label: 'Basketball' },
    { link: "/items", label: 'Top Picks' },
    { link: "/checklists", label: 'Checklists' },
  ]

  return <div className={styles['p']}>
    <div className={styles['ph']}>
      <div className={styles['pc']}>
        <nav className={styles['phr']}>
          <Link href="/" alt="Home" title="Home" aria-label="Home" className={styles['phl']}>
            <Image src={cdn("/images/logo.png")} className={"min-b-xs"} alt="AccuRips" fill={'object-fit'} style={{ maxWidth: "100%", objectFit: "contain" }} priority={true} sizes={'640x44'}></Image>
            <Image src={cdn("/images/logo-512.png")} className={"max-b-xs"} alt="AccuRips" fill={'object-fit'} style={{ maxWidth: "100%", objectFit: "contain" }} priority={true} sizes={'44x44'}></Image>
          </Link>
          <Link href={"/items"} alt={"Search Cards"} title={"Search Cards"} aria-label={"Search Cards"} className={"min-f-xs " + styles['phm']}>
            <span>{ 'Cards' }</span>
          </Link>
          <div className={styles['phrs']}>
            <form id="top_search_field" name="Top Search Bar" action={"/items"} method="GET" className={styles.tsf}>
              { SEARCH }
              <input type="text" name="q" defaultValue={props.searchParams?.q}></input>
            </form>
          </div>
          <Link href="/wishlist" alt="Wishlist" title="Wishlist" aria-label="Wishlist" className={styles['phib']}>{ HEART }</Link>
          <WebUserContext {...props}>
            <HeaderUser {...props}></HeaderUser>
          </WebUserContext>
        </nav>
      </div>
    </div>
    <div className={styles['pm']}>
      <div className={styles['pc']}>
        <div className={styles['pms']}>
          { highlights.map((h,i) => (
            <Link key={i} href={h.link} alt={h.label} aria-label={h.label} title={h.label}>{ h.label }</Link>
          ))}
        </div>
      </div>
    </div>
    <main>
      { !props.noAppStoreBanner &&
      <PageColumn {...props}>
        <AppPageBanner {...props}></AppPageBanner>
      </PageColumn>
      }
      { props.children }
    </main>
    <PageFooter {...props}></PageFooter>
  </div>

}