'use client'

import { useCurrentUser } from '@/views/UserContext'
import styles from './a.module.scss'
import Link from "next/link"
import { useEffect, useRef, useState } from 'react'
import ApiUtils from '@/utils/ApiUtils'
import Popup from '../components/popup/Popup'
import PopupBody from '../components/popup/PopupBody'
import useEventListener from '@/utils/EventListener'
import { USER } from '@/views/Icons'
import { isbot } from 'isbot'

const USER_MENUS = [
  // { label: 'Register', link: "/register" },
  { label: 'Login', link: "/login" },
]

const LOGGED_MENUS = [
  { label: 'My Cards', link: "/my-cards", enabled: false },
]

export default function HeaderUser(props) {

  const userContext = useCurrentUser()

  const userForm = useRef()

  const loggedIn = userContext?.user != null

  const [hasScans,setHasScans] = useState(false)
  const [userMode,setUserMode] = useState("login")
  const [form,setForm] = useState({})

  useEventListener("show-sign-in-up", event => {
    userForm.current.show()
  })

  useEffect(() => {
    if (loggedIn) {
      ApiUtils.getRequest("/server/api/cards/scans?count=1")
      .then(data => {
        setHasScans(data.total > 0)
      })
    }
  }, [loggedIn])

  useEffect(() => {
    if (!loggedIn && !isbot(navigator.userAgent)) {
      ApiUtils.setupVisitorId()
    }
  }, [loggedIn])

  const onFieldChange = (e) => {
    const name = e.target.name
    let value = e.target.value
    let suData = Object.assign({}, form)
    suData[name] = value
    setForm(suData)
    // if (mode == 'sign-up' && e.target.name == "email" && value.includes("@") && value.includes(".")) {
    //   // TODO: add debounce
    //   ApiUtils.getRequest("/platform/v1/users/exists?email=" + value)
    //   .then(data => {
    //     setExists(data.exists)
    //   })
    // }
  }

  const onSubmit = (e) => {

  }

  return <>
      { hasScans &&
      <Link href={"/scans"} alt={"My Cards"} title={"My Cards"} aria-label={"My Cards"} className={"min-f-xs " + styles['phm']}>
        <span>{ 'My Cards' }</span>
      </Link>
      }
      { !loggedIn && USER_MENUS.map((item,idx) => (
      <Link key={idx} href={item.link} alt={item.label} title={item.label} aria-label={item.label} className={"min-f-xs " + styles['phm']}>
        <span>{ item.label }</span>
      </Link>
      ))}
      { !loggedIn &&
      <Link href={"/login"} alt={"Account"} title={"Account"} aria-label={"Account"} className={styles['phib'] + ' max-f-xs'}>
        <i>{ USER }</i>
      </Link>
      }
      { loggedIn &&
      <Link href={"/my-cards"} alt={"My Cards"} title={"My Cards"} aria-label={"My Cards"} className={styles['phib'] + ' max-f-xs'}>
        <i>{ USER }</i>
      </Link>
      }
      { loggedIn &&
      <Link href={ "/" } className={"min-f-xs " + styles['phm']} style={{textTransform: 'none'}}>
        <span>{ userContext.user.first_name || userContext.user.email.split("@")[0] }</span>
      </Link>
      }
      <Popup ref={userForm} title={userMode == "login" ? "Sign In" : "Sign Up"} className={styles.popl}>
        <PopupBody>
          <form className={styles.popfrm} id={ userMode == "login" ? "user_signin_popup" : "user_signup_popup" } name={ userMode == "login" ? "Sign In Popup" : "Sign Up Popup" } onSubmit={e => onSubmit(e)}>
            <div className={styles.frmrws}>
              <div className={styles.frmrwsc}>
                <div className={styles.frmi}>
                  <input type="text" name="email" value={form.email || ''} onChange={e => onFieldChange(e)} aria-label="Email" placeholder="Email"></input>
                </div>
              </div>
              <div className={styles.frmrwsc}>
                <div className={styles.frmi}>
                  <input type="text" name="first_name" value={form.first_name || ''} onChange={e => onFieldChange(e)} aria-label="First Name" placeholder="First Name"></input>
                </div>
              </div>
              <div className={styles.frmrwsc}>
                <div className={styles.frmi}>
                  <input type="text" name="last_name" value={form.last_name || ''} onChange={e => onFieldChange(e)} aria-label="Last Name" placeholder="Last Name"></input>
                </div>
              </div>
            </div>
          </form>
        </PopupBody>
      </Popup>
  </>

}