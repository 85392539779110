import React from 'react';
import styles from "./a.module.scss"

export default function PopupBody(props) {

  return <div className={styles.body + (props.className ? ' ' + props.className : '')}>
    { props.children }
  </div>

}
