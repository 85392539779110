'use client'

import { UserProvider } from './UserContext'
import AuthUserWrapper from './AuthUserWrapper'

export default function WebUserContext(props) {

  return <UserProvider {...props}>
    <AuthUserWrapper {...props} web={true}>
      { props.children }
    </AuthUserWrapper>
  </UserProvider>

}