import { cdn } from '@/utils/Helpers'
import styles from './a.module.scss'

export default function AppPageBanner(props) {

  return <div className={styles.ad + ' ' + styles['ad-app']}>
    <div className={styles['ad-sdw']}></div>
    <div className={styles.adr}>
      <div>
        <div className={styles.adtl}>Scan. Review. Sell.</div>
        <div className={styles.adst}>In as less as 5 seconds. <b>Download the app.</b></div>
      </div>
      <div className={styles.stc}></div>
      <div>
        <div className={styles.adb}>
          <a className={styles['layout-apps-badge']} href="https://apps.apple.com/us/app/accurips/id6497653383">
            <img src={cdn("/images/app-store-badge.svg")}></img>
          </a>
          {/* <a className={styles['layout-apps-badge']} href="https://play.google.com/apps/4701595852447777485">
            <img src={cdn("/images/play-store-badge.png")}></img>
          </a> */}
        </div>
      </div>
    </div>
  </div>

}