import { cache } from 'react';
import { cdn } from '@/utils/Helpers'
import styles from './a.module.scss'
import PageColumn from './PageColumn'
import Link from 'next/link'
import { FACEBOOK, INSTAGRAM, TWITTER, YOUTUBE } from '@/views/Icons'
import axios from 'axios'

const loadConfig = cache(async (params) => {
  if (process.env.API_SERVER) {
    const resp = await axios.get(process.env.API_SERVER + "/api/partner/footer")
    const data = resp.data
    return data
  } else {
    return { checklists: [], sports: ["Baseball", "Basketball", "Football", "Hockey", "UFC"], companies: ["Topps", "Panini", "Donruss", "Bowman"] }
  }
})

export default async function PageFooter(props) {

  const social = [
    { icon: TWITTER, link: "https://x.com/accurips", label: "X (Twitter)" },
    { icon: FACEBOOK, link: "https://www.facebook.com/profile.php?id=61559157283246", label: "Facebook" },
    { icon: INSTAGRAM, link: "https://www.instagram.com/accurips", label: "Instagram" },
    { icon: YOUTUBE, link: "https://www.youtube.com/@AccuRips", label: "YouTube" },
  ]

  const config = await loadConfig(props.params)

  return <footer className={styles.ft}>
    <PageColumn>
      <div className={styles.ftp}>
        <div className={styles.ftc}>
          <div className={styles.ftli}>
            <div className={styles.ftic}>
            { social.map((l,i) => (
              <Link key={i} className={styles.fticb} href={l.link} title={l.lable}>{ l.icon }</Link>
            ))}
            </div>
            <div className={styles.ftgap}></div>
            <div>@2024 AccuRips LLC</div>
          </div>
        </div>
        <div className={styles.ftc}>
          <div className={styles.ftli}>
            { config.checklists.map((c,i) => (
            <div key={i}><Link href={"/items/" + c} alt={c} aria-label={c} title={c}>{c}</Link></div>
            ))}
          </div>
        </div>
        <div className={styles.ftc}>
          <div className={styles.ftli}>
            { config.sports.map((c,i) => (
            <div key={i}><Link href={"/items/s/" + c} alt={c} aria-label={c} title={c}>{c}</Link></div>
            ))}
          </div>
        </div>
        <div className={styles.ftc}>
          <div className={styles.ftli}>
            { config.companies.map((c,i) => (
            <div key={i}><Link href={"/items/c/" + c} alt={c} aria-label={c} title={c}>{c}</Link></div>
            ))}
          </div>
        </div>
        <div className={styles.fts}></div>
        <div className={styles.ftc}>
          <div className={styles.ftli}>
            <div><Link href={"/products"} alt="Subscriptions" aria-label="Subscriptions" title="Products & Subscriptions">Subscriptions</Link></div>
            <div><Link href={"/aboutus"} alt="About Us" aria-label="About Us" title="About Us">About Us</Link></div>
            <div><Link href={"/privacy"} alt="Privacy Policy" aria-label="Privacy Policy" title="Privacy Policy">Privacy Policy</Link></div>
            <div><Link href={"/tos"} alt="Terms of Service" aria-label="Terms of Service" title="Terms of Service">Terms of Service</Link></div>
          </div>
        </div>
      </div>
    </PageColumn>
  </footer>

}