'use client'

import { useState } from 'react';
// import { Form, FieldCells, FieldCell, Field, FieldLabel, Button, Flex } from '@ginieapp/ginie-ui';
import styles from './ar.module.scss'
import Image from "next/image";
import ApiUtils from '@/utils/ApiUtils';
import { useRouter } from 'next/navigation';
import { cdn } from '@/utils/Helpers';

export default function LoginForm(props) {

  const router = useRouter()

  const [form,setForm] = useState({})

  const onChange = (e) => {
    let data = Object.assign({}, form)
    data[e.target.name] = e.target.value
    setForm(data)
  }

  const login = (e) => {
    if (e) e.preventDefault()
    ApiUtils.postRequest("/server/api/users/login", { username: form.username, password: form.password })
    .then(data => {
      if (props.onLogin) {
        props.onLogin(e)
      } else {
        if (data.user && data.user.is_admin) {
          router.push("/admin/scans")
        } else {
          router.push("/")
        }
      }
    })
  }

  return <form id="login_form" name="Login Form" onSubmit={e => login(e)} className={props.className}>
    { props.logo &&
    <>
    <div className={styles.popup_logo}>
      <Image src={cdn("/images/logo.png")} className={"min-b-xs"} alt="AccuRips" fill={'object-fit'} style={{ maxWidth: "100%", objectFit: "contain" }} priority={true} sizes={'640x44'}></Image>
    </div>
    <div></div>
    <div></div>
    </>
    }
    <input type="text" name="username" value={form.username || ''} placeholder="Email" onChange={e => onChange(e)}></input>
    <input type="password" name="password" value={form.password || ''} placeholder="Password" onChange={e => onChange(e)}></input>
    <button type="submit">Login</button>
    {/* <div style={{textAlign:'center'}}><a href="/register">Register</a></div> */}
  </form>


}