export function cdn(url) {
  return (process.env.NEXT_PUBLIC_CDN || process.env.NEXT_PUBLIC_LOCAL_CDN) + (url || "")
}
export function formatCurrency(value, decimals, locale, currency) {
  return new Intl.NumberFormat(locale || (typeof(window) == 'object' ? window.locale || 'en' : 'en'), {
    style: 'currency',
    currency: currency || (typeof(window) == 'object' ? window.currencyCode || 'USD' : 'USD'),
    maximumFractionDigits: decimals || 0
  }).format(parseFloat(value));
}
export function formatNumber(value, decimals, locale) {
  return new Intl.NumberFormat(locale || (typeof(window) == 'object' ? window.locale || 'en' : 'en'), {
    maximumFractionDigits: decimals || 0
  }).format(parseFloat(value));
}