import axios from 'axios';
import { getCookie, setCookie, deleteCookie } from 'cookies-next';

export const USER_AGENT = "AccuRips/v1.0"

const SITE_COOKIE_NAME = "_ar_vid"

export default class ApiUtils {

  static events = []

  static getFullUrl = (urlStr) => {
    if (urlStr.indexOf("http") == 0) {
      return urlStr;
    }
    return urlStr
  }
  
  static defaultHeaders = () => {
    let headers = {
      Accept: 'application/json',
      // 'Content-Type': 'application/json',
      // 'User-Agent': USER_AGENT,
    }
    if (getCookie(SITE_COOKIE_NAME)) {
      headers['X-AR-VID'] = getCookie(SITE_COOKIE_NAME)
    }
    if (getCookie("_ar_token")) {
      headers['Authorization'] = "Bearer " + getCookie("_ar_token")
    }
    return headers
  }
  
  static visitorId = () => {
    return getCookie(SITE_COOKIE_NAME)
  }
  
  static setupVisitorId = () => {
    let vid = getCookie(SITE_COOKIE_NAME)
    if (!vid) {
      ApiUtils.postRequest("/server/api/users/register_visitor")
      .then(data => {
        setCookie(SITE_COOKIE_NAME, data.vid)
      })
    }
  }
  
  static authToken = () => {
    return getCookie("_ar_token")
  }
  
  static readAuthHeader = (response) => {

    if (response.headers.get('Authorization')) {
      setCookie("_ar_token", response.headers.get('Authorization').split(" ")[1])
    }
  }

  static checkAuth = () => {
    return ApiUtils.getRequest("/server/api/users/me")
  }

  static getRequest = (urlStr, params) => {

    let url = this.getFullUrl(urlStr)
    if (urlStr.indexOf("?") < 0) url += "?"
    if (params) {
      Object.keys(params).forEach(function (key) {
        if (params[key]) {
          url += "&" + encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
        }
      });
    }

    if (process.env.NODE_ENV == "developement") console.log("GET " + url)

    return axios.get(url, {
      headers: this.defaultHeaders()
    }).then(response => {
      this.readAuthHeader(response)
      if (response.status == 200) {
        return response.data
      } else if (response.status == 202) {
        return {}
      } else {
        let error = { http: { status: response.status, url: url } }
        this.logError("HTTP (" + response.status + ") GET ERROR - " + url, error, response)
        throw new Error("HTTP (" + response.status + ") GET ERROR!")
      }
    }).catch(error => {
      let err = { http: { status: error.status, url: url }, error: error }
      this.logError("HTTP GET ERROR - " + url, err)
      throw new Error("HTTP GET ERROR!")
    })
  }

  static postRequest = (urlStr, data, method) => {
    let url = this.getFullUrl(urlStr)
    var jsonBody = data
    var contentType = 'application/json'

    if (process.env.NODE_ENV == "developement") console.log("POST " + url)
    
    let headers = this.defaultHeaders()
    headers["Content-Type"] = contentType

    return axios.post(url, data, {
      headers: headers
    }).then(response => {
      this.readAuthHeader(response)
      if (response.status == 200) {
        return response.data
      } else if (response.status == 202) {
        return {}
      } else {
        let error = { http: { status: response.status, url: url } }
        this.logError("HTTP (" + response.status + ") GET ERROR - " + url, error, response)
        throw new Error("HTTP (" + response.status + ") GET ERROR!")
      }
    }).catch(error => {
      let err = { http: { status: error.status, url: url }, error: error }
      this.logError("HTTP GET ERROR - " + url, err)
      throw new Error("HTTP GET ERROR!")
    })

  }

  static postMultipart = (urlStr, formData) => {
    let url = this.getFullUrl(urlStr)
    
    let headers = this.defaultHeaders()
    delete headers['Content-Type']

    if (process.env.NODE_ENV == "developement") console.log('POST ' + url)

    return axios.post(url, formData, {
      headers: headers
    }).then(response => {
      this.readAuthHeader(response)
      if (response.status == 200) {
        return response.data
      } else if (response.status == 202) {
        return {}
      } else {
        let error = { http: { status: response.status, url: url } }
        this.logError("HTTP (" + response.status + ") GET ERROR - " + url, error, response)
        throw new Error("HTTP (" + response.status + ") GET ERROR!")
      }
    }).catch(error => {
      let err = { http: { status: error.status, url: url }, error: error }
      this.logError("HTTP GET ERROR - " + url, err)
      throw new Error("HTTP GET ERROR!")
    })

  }
   
  static clearToken = () => {
    deleteCookie("_ar_token", { path: "/" })
  }
  
  static logError = async (message, error, response) => {
    if (error && error.http && response) error.http.body = await response.text()
    if (process.env.NODE_ENV == 'development') {
      if (message) console.error(message, error)
      if (!message) console.error(error)
    }
  } 

  static logEvent = (event) => {
    this.events.push(event)
    setTimeout(() => {
      if (ApiUtils.events.length > 0) {
        const array = [...ApiUtils.events]
        ApiUtils.events = []
        ApiUtils.postRequest("/api/visitor/track", { payload: { events: array } })
      }
    }, 5000)
  }

}

export function logABEvent(site, name, type, component, value, data, vehicle) {
  const props = {
    test_mode: site.abMode,
    test_value: value,
    site: site.site_id,
    version: process.env.NEXT_PUBLIC_VERSION,
    car: vehicle
  }
  if (process.env.NODE_ENV == 'development') console.log("A/B LOG", [name, type, component, data, props])
  if (typeof logDMEvent == 'function') logDMEvent(name, type, component, data, props)
  
}
