import PusherConnector from "@/utils/PusherConnector";
import { createContext, useContext, useState } from "react";

const UserContext = createContext();

export function UserProvider({children}) {

    const [user, setUser] = useState(null);

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
            { user && <PusherConnector></PusherConnector> }
        </UserContext.Provider>
    )
}

export function useCurrentUser() {
    return useContext(UserContext)
}
