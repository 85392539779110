'use client'

import { useEffect } from 'react'
import Pusher from 'pusher-js'
import { useCurrentUser } from '@/views/UserContext';
import { emitEvent } from './EventListener';

export default function PusherConnector(props) {
  
  const currentUser = useCurrentUser()

  let pusher = null;

  useEffect(() => {

    try {

      if (pusher == null) {
        pusher = new Pusher(currentUser.pusher?.key || process.env.NEXT_PUBLIC_PUSHER_KEY || process.env.NEXT_PUBLIC_LOCAL_PUSHER_KEY, {
          cluster: currentUser.pusher?.cluster || process.env.NEXT_PUBLIC_PUSHER_CLUSTER || 'us2',
          encrypted: true
        })
      }
      
      pusher.connection.bind( 'error', function( err ) {
        console.error("---- PUSHER ERROR")
        console.error(err)
        if( err.data?.code === 4004 ) {
          log('Over limit!');
        }
      });
  
      if (props.channelId) subscribe(props.channelId)
  
      // subscribe("ar--scan-results")
      subscribe("ar--u-" + currentUser.user.user_id)  
  
    } catch (e) {
    }

    // return () => {
    //   if (pusher && process.env.NODE_ENV == 'development') console.log("PUSHER ---> DISCONNECT")
    //   if (pusher) pusher.disconnect();
    // }
    
  }, [])

  const subscribe = (channelId) => {
    const channel = pusher.subscribe(channelId);
    console.log("PUSHER: Subscribed to " + channelId)
    channel.bind_global((event, data) => {
      if (event.indexOf('pusher:') != 0) {
        data["__channel_id"] = channelId
        // if (process.env.NODE_ENV == 'development') console.log("PUSHER MESSAGE: ", data)
        console.log("PUSHER MESSAGE: " + event, data)
        emitEvent(event, data)
      }
    });
  }

  return <></>
  
}